import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import system from './system'
import user from './user'
import betting from './betting'

// 绑定 chrome redux-devtool
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// 创建数据存储仓库
const store = createStore(combineReducers({ system, user, betting }), composeEnhancer(applyMiddleware(thunk)))

// window.store = store
// console.log('store', window.store.getState())

export default store
