export const getDay = (val) => {
  //封装的日期方法
  let today = new Date(val)
  let tYear = today.getFullYear()
  let tMonth = today.getMonth()
  let tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  return tYear + '-' + tMonth + '-' + tDate
  function doHandleMonth(month) {
    let m = month
    if (month.toString().length === 1) {
      m = '0' + month
    }
    return m
  }
}

export const deviceType = () => {
  //判断 ios android设备
  let n = navigator.userAgent,
    t = n.indexOf('Android') > -1 || n.indexOf('Linux') > -1
  return t === !0 ? 'android' : 'ios'
}

/** 分割开奖结果 */
export const lotteryOpenSplit = (openInfo, char = '?') => {
  if (!openInfo) return Array(5).fill(char)
  // split(/\s+|,+\s*|/);
  if (openInfo.includes('|')) {
    return openInfo.split('|')
  }
  if (openInfo.includes(',')) {
    return openInfo.split(',')
  }
  if (openInfo.includes(' ')) {
    return openInfo.split(' ')
  }
  return openInfo.split('')
}

/**
 * @param {string|number} num
 * @param {number} len 需要保留几位小数
 * @description 保留小数点后几位，不做四舍五入，只做截取
 */
export const toFixed_New = (num, len = 1) => {
  var str = num.toString()
  var index = str.indexOf('.')
  var str0 = Array(len).fill(0).join('')
  var newStr
  if (index !== -1) {
    str = str + str0
    newStr = str.slice(0, index + len + 1)
  } else {
    newStr = str + '.' + str0
  }
  return newStr
}

export const numDay = (day) => {
  //封装传入数子获取日期
  var today = new Date()
  var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetday_milliseconds)
  var tYear = today.getFullYear()
  var tMonth = today.getMonth()
  var tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  return tYear + '-' + tMonth + '-' + tDate
  function doHandleMonth(month) {
    var m = month
    if (Number(month.toString().length) === 1) {
      m = '0' + month
    }
    return m
  }
}

export const getQueryVariable = (variable) => {
  var query = window.location.href.split('?')[1]
  console.log(query)
  var vars = []
  if (query && query.indexOf('&') > -1) {
    vars = query.split('&')
  }
  console.log(vars)
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

// 银行卡列表
export const bankCardList = [
  {
    cn_name: '广东发展银行',
    name: 'gdfz_bank',
    logo: 'gdfz_bank'
  },
  {
    cn_name: '广发银行',
    name: 'gf_bank',
    logo: 'gf_bank'
  },
  {
    cn_name: '华夏银行',
    name: 'hx_bank',
    logo: 'hx_bank'
  },
  {
    cn_name: '平安银行',
    name: 'pa_bank',
    logo: 'pa_bank'
  },
  {
    cn_name: '上海浦东发展银行',
    name: 'shpdfz_bank',
    logo: 'shpdfz_bank'
  },
  {
    cn_name: '兴业银行',
    name: 'xy_bank',
    logo: 'xy_bank'
  },
  {
    cn_name: '招商银行',
    name: 'zs_bank',
    logo: 'zs_bank'
  },
  {
    cn_name: '中国工商银行',
    name: 'zggs_bank',
    logo: 'zggs_bank'
  },
  {
    cn_name: '中国光大银行',
    name: 'zggd_bank',
    logo: 'zggd_bank'
  },
  {
    cn_name: '中国建设银行',
    name: 'zgjs_bank',
    logo: 'zgjs_bank'
  },
  {
    cn_name: '中国交通银行',
    name: 'zgjt_bank',
    logo: 'zgjt_bank'
  },
  {
    cn_name: '中国民生银行',
    name: 'zgms_bank',
    logo: 'zgms_bank'
  },
  {
    cn_name: '中国农业银行',
    name: 'zgny_bank',
    logo: 'zgny_bank'
  },
  {
    cn_name: '中国银行',
    name: 'zg_bank',
    logo: 'zg_bank'
  },
  {
    cn_name: '中国邮政储蓄银行',
    name: 'zgyzcx_bank',
    logo: 'zgyzcx_bank'
  },
  {
    cn_name: '中国招商银行',
    name: 'zgzs_bank',
    logo: 'zgzs_bank'
  },
  {
    cn_name: '中信银行',
    name: 'zx_bank',
    logo: 'zx_bank'
  },
  {
    cn_name: 'USDT',
    name: 'USDT',
    logo: 'USDT'
  },
  {
    cn_name: 'ERC20',
    name: 'ERC20',
    logo: 'ERC20'
  },
  {
    cn_name: 'TRC20',
    name: 'TRC20',
    logo: 'TRC20'
  }
]

// timetoStr 时间戳转换为时间格式 不包含日期
const timetoStr = (time2) => {
  let time = new Date(parseInt(time2) * 1000)
  let str = ''
  if (time.getHours() < 10) {
    str += '0'
  }
  str += time.getHours().toString()
  str += ':'
  if (time.getMinutes() < 10) {
    str += '0'
  }
  str += time.getMinutes().toString()
  str += ':'
  if (time.getSeconds() < 10) {
    str += '0'
  }
  str += time.getSeconds().toString()
  return str
}

// 获取当前时间是否在某一时间段内
//beginTime,endTime,nowTime为时间戳类型
export const is_time_limit = (beginTime, endTime, nowTime) => {
  // 是否在营业时间内
  // beginTime, endTime, nowTime:time_range(“09:00:00”,“21:00:00”,“12:12:12”);
  // 将时间戳转化为时间格式
  beginTime = timetoStr(beginTime)
  endTime = timetoStr(endTime)
  nowTime = timetoStr(nowTime)

  var strb = beginTime.split(':')
  if (strb.length !== 3) {
    return false
  }

  var stre = endTime.split(':')
  if (stre.length !== 3) {
    return false
  }

  var strn = nowTime.split(':')
  if (stre.length !== 3) {
    return false
  }
  var b = new Date()
  var e = new Date()
  var n = new Date()

  b.setHours(strb[0])
  b.setMinutes(strb[1])
  b.setSeconds(strb[2])
  e.setHours(stre[0])
  e.setMinutes(stre[1])
  e.setSeconds(stre[2])
  n.setHours(strn[0])
  n.setMinutes(strn[1])
  n.setSeconds(strn[2])
  if (n.getTime() - b.getTime() > 0 && n.getTime() - e.getTime() < 0) {
    // console.log('在该时间范围内！') ;
    return true
  } else {
    // console.log ("当前时间是：" + n.getHours () + ":" + n.getMinutes () + ":"+n.getSeconds()+"，不在该时间范围内！");
    return false
  }
}
