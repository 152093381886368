import reportWebVitals from '@/reportWebVitals'
// import './index.css';
import App from '@@/App'
import '@@T/index.scss'
import React from 'react'
import ReactDOM from 'react-dom'
import {
    FpjsProvider,
    // defaultEndpoint,
    // defaultScriptUrlPattern
} from '@fingerprintjs/fingerprintjs-pro-react'
console.log(process.env)

let fp = process.env.NEED_STAND.fp

let thirdImg = process.env.NODE_ENV === "development" ? 'http://n2hz.h5.stg.front.plg/onlyH5GameImg/' : window.location.protocol + '//' + window.location.hostname + '/onlyH5GameImg/'

React.Component.prototype.thirdImg = thirdImg;
console.log(process.env.NODE_ENV + '  ' + process.env.AliTYPE + '  ' + React.Component.prototype.thirdImg)



init()

function init() {
    ReactDOM.render(<FpjsProvider
        loadOptions={{
            apiKey:fp,
            // endpoint: ["<CUSTOM_ENDPOINT>", defaultEndpoint],
            // scriptUrlPattern: ["<CUSTOM_SCRIPT_URL>", defaultScriptUrlPattern],
            // region: "Asia"
        }}
    >
        <App />
    </FpjsProvider>, document.getElementById('root'))
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
