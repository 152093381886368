import React, {useEffect} from "react";
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react'


function Fp() {
    const {
        isLoading,
        error,
        getData,
    } = useVisitorData();

    useEffect(() => {
        initFp()
        return () => {


        }
    }, [])

    function initFp() {
        getData()
            .then((data) => {
                // debugger
                // do something with the visitor data
                // for example, append visitor data to the form data to send to your server
                console.log(data)
                React.Component.prototype.ioBB = data.visitorId
            })
            .catch((error) => {
                // Handle error
            })

    }

    return (
        <>

        </>
    )

}

export default Fp
