import {commonGet} from '@/api'
import store from '@/store'
import {setLotteries} from '@/store/system/actionCreators'
import {getItem, setItem} from '@/utils/storage'
import {useEffect, useRef} from 'react'
import {deviceType} from './common'
import {HashRouter} from 'react-router-dom'
import {Dialog} from "antd-mobile";
import React from "react";

const router = new HashRouter()

/** 解决精度缺失问题 */
export function fix_jingdu(f, digit = 10) {
    if (!f) return 0
    let m = Math.pow(10, digit)
    return Math.round(f * m, 10) / m
}

/** 时间格式化 */
export function timeFormatter(seconds) {
    var h = Math.floor(seconds / 60 / 60)
    var m = Math.floor((seconds - h * 60 * 60) / 60)
    var s = seconds - h * 60 * 60 - m * 60

    return [('00' + h).slice(-2), ('00' + m).slice(-2), ('00' + s).slice(-2)]
}

/** 初始化开奖到计时器 */
export function initTimer(item) {
    store.dispatch(setLotteries({code: item.code, lottery: item}))

    const channel = store.getState().system.channel
    channel.bind(`bet_${item.code?.toUpperCase()}`, (data) => {
        const isInBet = store.getState().system.isInBet
        if (!isInBet) store.dispatch(setLotteries({code: item.code, lottery: JSON.parse(data)}))
    })
}

/** 分割开奖结果 */
export function lotteryOpenSplit(openInfo, char = '?') {
    if (!openInfo) return Array(5).fill(char)
    if (openInfo.includes('|')) return openInfo.split('|')
    if (openInfo.includes(',')) return openInfo.split(',')
    if (openInfo.includes(' ')) return openInfo.split(' ')
    return openInfo.split('')
}

export function numFormatter(ruleParams, val) {
    var arr = val.split('')
    var data = []
    var sxNum = 1
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] === '|') {
            sxNum++
            data.push(arr[i])
            continue
        }
        var newChar = ruleParams.decode(arr[i], sxNum)
        data.push(newChar)
    }
    return data
}

export function useIsMounted() {
    const isMounted = useRef(false)

    useEffect(() => {
        isMounted.current = true
        return () => (isMounted.current = false)
    }, [])

    return isMounted
}

// 防抖
export function debounce(fn, delay) {
    var timer // 维护一个 timer
    return function () {
        var _this = this // 取debounce执行作用域的this
        var args = arguments
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(function () {
            fn.apply(_this, args) // 用apply指向调用debounce的对象，相当于_this.fn(args);
        }, delay)
    }
}

//储存客服链接
export async function httpService() {
    let token = getItem('token')
    let url = ``
    if (token) {
        url = `customer/url/${deviceType()}/user`
    } else {
        url = `customer/guest/url/${deviceType()}/user`
    }
    let res = await commonGet(url)
    setItem('kefuUrl', res.url)
}

export async function appGoService() {


    let res = await commonGet('customer/shortcut')
    if (res.status) {
        appGoService800()
    }else {
        router.history.push('/kefu')
    }

}

export async function appGoService800() {
    let kefuUrl = getItem('kefuUrl')
    if (!kefuUrl) {
        Dialog.show({
            title: '温馨提示',
            content: `检测到客服地址不存在`,
            closeOnAction: true,
            actions: [
                {
                    key: 'confirm',
                    text: '重试',
                    onClick: () => {
                        httpService()
                    }
                }
            ]
        })
        return
    }
    if (process.env.AliTYPE === 'wap') {
        let aliOpen
        aliOpen = window.open('')
        if (aliOpen) {
            aliOpen.location.href = kefuUrl
        } else {
            Dialog.show({
                title: '温馨提示',
                content: `检测到被拦截，手动操作`,
                closeOnAction: true,
                actions: [
                    {
                        key: 'confirm',
                        text: '立即跳转',
                        onClick: () => {
                            window.open(kefuUrl)
                        }
                    }
                ]
            })

        }

    }
}


export function jumpTo(url) {
    let aliOpen
    if (process.env.AliTYPE === 'wap') {
        aliOpen = window.open('')
        if (aliOpen) {
            aliOpen.location.href = url
        } else {
            window.open(url)
        }
    }
}

