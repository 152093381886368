import {deviceType} from '@/utils/common'
import {DELETE, GET, PATCH, POST, PUT} from '../http'

export const login = (data) => POST('user/login', data, true)
export const getLotteries = () => GET('lotteries', true)
export const getCollections = () => GET('lotteries/collections', true)
export const getVersion = () => GET('version', true)
export const purchases = (data) => GET(`purchases/${data.type}/${data.start}/${data.end}`, true)
export const getNextOpen = (data) => GET(`lotteries/${data.code}/schedules/next`, data.loading)
export const getLotteryInquiry = (data) => GET(`lotteries/${data.code}/purchases/inquiry`, true)
export const getPlayInquiry = (data) => GET(`lotteries/${data.code}/games/${data.id}/purchases/inquiry`, true)
export const getGameInfo = (data) => {
    return new Promise(resolve => {
        console.log(data)
        resolve(GET(`lotteries/${data.code}/games`, true))
    })
}
export const betSubmit = (data) => POST(`lotteries/${data.code}/purchases3`, data.params, true)
export const getLatestOpen = (data) => GET(`lotteries/${data.code}/histories/latest`, data.loading)
export const getHistories = (data) => {
    let query = ''
    if (data.range === 0) {
        if (data.laterThan) {
            query += `?lt=${data.laterThan}`
        }
    } else {
        query += `?limit=${data.range}`
        if (data.laterThan) {
            query += `&lt=${data.laterThan}`
        }
    }
    return GET(`lotteries/${data.code}/histories${query}`, true)
}
export const getTips = (data) => POST(`lotteries/tips`, data.params, true)
export const getBalance = () => GET(`account`, true)
export const getAllHistories = (data) => GET(`lotteries/histories${data?.greaterThan ? `?gt=${data.greaterThan}` : ''}`, true)
export const getPurchases = (data) => GET(`purchases/${data.type}/${data.startDate}%2000:00:00/${data.endDate}%2023:59:59${data.laterThan ? `?lt=${data.laterThan}` : ''}`, true)
export const getTracks = (data) => GET(`purchases/repeats/${data.type}/${data.startDate}%2000:00:00/${data.endDate}%2023:59:59`, true)
export const getOverview = (data) => POST(`purchases/${data.type}`, data.params, true)
export const cancellations = (data) => POST(`purchases/${data.id}/cancellations`, {}, true)
export const getPurchasesById = (data) => GET(`purchases/${data.id}`, true)
export const getTrackesById = (data) => GET(`purchases/repeats/${data.id}`, true)
export const stopTrack = (data) => POST(`purchases/repeats/${data.id}/stopped`, data.params, true)
export const getRecommands = () => GET('lotteries/recommands', true)
export const getLotteriesWithoutLogin = () => GET('lotteries/noLogin', true)
export const loginRequest = (data) => POST('user/login', data, true)
export const isLoginvalidateCodeHttp = () => POST('user/show_login_validate_code') // 登录是否校验验证码
export const isRegvalidateCodeHttp = () => POST('user/show_register_validate_code') // 注册是否校验验证码
export const registerHttp = (data) => POST('user/register', data, true) //注册
export const getCategories = () => GET(`help/categories`, true) //猜你想问
export const moneyHttp = () => GET(`account`, true) // 金额
export const swiperHttp = () => GET(`promotions?device=${process.env.AliTYPE === 'wap' ? 'h5' : deviceType()}`, true) // 轮播图
// export const promotionsHttps = () => GET(`promotions`, true) // 活动轮播图
export const gamesPlatforms = () => GET(`games/menu/platforms/${process.env.AliTYPE === 'wap' ? 'h5' : deviceType()}`, true) // 三方娱乐
export const mfHomeMenu = () => GET(`games/menu/all/${process.env.AliTYPE === 'wap' ? 'h5' : deviceType()}`, true) // 首页所有分类
export const getHelpCategories = (data) => GET(`help?terminal_type=1&category_id=${data}`, true) // 帮助中心接口
export const defaultNotransfers = () => GET(`user/notransfers/switch/getstate`, true) // 设置的默认状态
export const getNotransfers = (data) => GET(`user/notransfers/switch/setstate/${data}`, true) // 设置的转换钱包按钮
export const changeHeadHttp = (data) => PATCH(`user`, data, true) //修改设置信息接口
export const changetrueNameHttp = (data) => PATCH(`user_info`, data, true) //修改真实姓名接口
export const updataHttp = () => {
    return GET(`user`, true).then(res => {
        return new Promise(resolve => {
            if (res.is_demo) {
                res.isTryGame = 1
                res.nickname =  '试玩账号'
            }else {
                res.isTryGame = 0
            }
            resolve(res)
        })
    })
}//更新信息的接口
export const bindMobileEmailHttp = (url, data) => POST(url, data, true) // 确认绑定手机and绑定邮箱
export const sendSmsHttp = (data) => GET(`user/sendSms/${escape(data)}`, true) // 手机发送的验证码
export const sendEmailHttp = (data) => POST(`user/sendEmail`, data, true) // email 发送的验证码
export const settingPwdHttp = (data) => POST(`user/password`, data, true) // 修改密码
export const accountPwdHttp = (data) => POST(`account/password`, data, true) // 修改资金密码
export const commonGet = (url, data) => GET(url, data, true)
export const getAnnouncements = () => GET(`messages/announcements`, true) // 公告 messages
export const getMessages = () => GET(`messages`) //站内信
export const getMessagesPage = () => GET(`users/chat`) //获取站内聊天页面的地址
export const inquiryHttp = () => GET(`account/deposits/inquiry`, true) //支付方式导航数据接口  wallets/thirdparties
export const depositsHttp = (data) => POST(`account/deposits`, data, true) // 充值三方跳转获取url页面的接口
export const getRechangeName = () => GET(`account/deposits/name`, true) // 获取充值姓名列表接口
export const isSuccesswithdrawals = (data) => POST(`account/withdrawals`, data, true) // 提款
export const getDigitalRate = () => GET(`account/digital/rate`, true) //获取USDT汇率
export const getBankcards = () => GET(`account/bankcards`, true) //获取银行卡信息
export const pwdValidation = (data) => POST(`account/password/validation`, data, true) // 密码校验
export const lockCardHttp = (url, data) => POST(url, data, true) // 锁卡
export const oldValidationBanks = (id, data) => POST(`account/bankcards/${id}/validation`, data, true) // 校验旧银行卡
export const delBankcards = (id, data) => DELETE(`account/bankcards/${id}`, data, true) //删除银行卡
export const newDelBankcards = (id, data) => DELETE(`account/bankcards/${id}/new`, data, true) //新版删除银行卡
export const selectedBanks = () => GET(`options/banks`, true) //  选择什么银行的信息
export const addBanksHttp = (data) => POST(`account/bankcards`, data, true) //添加银行卡


export const alipayInquiry = () => GET(`account/withdrawals/alipay/inquiry`, true) //  支付宝数据
export const addAlipayHttp = (data) => POST(`account/alipay`, data, true) //  添加支付宝
export const getAlipayHttp = (data) => GET(`account/alipay`, data, true) //  获取已绑定支付宝列表
export const newDelAlipayHttp = (id, data) => DELETE(`account/alipay/${id}/new`, data, true) //  新删除Usdt
export const delAlipayHttp = (id, data) => DELETE(`account/alipay/${id}`, data, true) //  删除支付宝


export const wechatInquiry = () => GET(`account/withdrawals/wechat/inquiry`, true) // 微信
export const addWechatHttp = (data) => POST(`account/wechat`, data, true) //  添加微信
export const getWechatHttp = (data) => GET(`account/wechat`, data, true) //  获取已绑定微信列表
export const newDelWechatHttp = (id, data) => DELETE(`account/wechat/${id}/new`, data, true) //  新删除U微信
export const delWechatHttp = (id, data) => DELETE(`account/wechat/${id}`, data, true) //  删除微信


export const digitalUsdtHttp = () => GET(`account/withdrawals/digital/inquiry?is_mobile=1`, true) // usdt 数据
export const transfersHttp = () => GET(`account/transfers`, true) // 转账 数据
export const transferInquiry = () => GET(`account/withdrawals/inquiry?is_mobile=1`, true) // 转账
export const transferStatus = () => GET(`account/transfers/status`, true)
export const transferOut = (id, data) => POST(`wallets/thirdparties/${id}/transfers/out`, data, true) //  转出
export const transferIn = (id, data) => POST(`wallets/thirdparties/${id}/transfers/in`, data, true) //  转入
export const delUsdtHttp = (id, data) => DELETE(`account/digital/${id}`, data, true) //  删除Usdt
export const newDelUsdtHttp = (id, data) => DELETE(`account/digital/${id}/new`, data, true) //  新删除Usdt
export const addUsdtHttp = (data) => POST(`account/digital`, data, true) //  增加Usdt
export const oneKeyHttp = () => GET(`thirdparties/transfers/allin`, true) //  资金回收
export const lotteryOverviewHttp = (data) => POST(`purchases/overview`, data, true) // 彩票注单总投注
export const gameOverviewHttp = (data) => POST(`purchases/overviewThird`, data, true) // 彩票注单总投注
export const detailsHttp = (id) => GET(`purchases/${id}`, true) // 彩票注单列表数据
export const fundsHttp = (data) => POST(`reports/agent/funds`, data, true) // 交易记录   ' + that.username + '/0'
export const bindUser = (username) => GET(`user/getUserDetailByUserName/${username}`, true) // 忘记密码的获取用户
export const forgetBindMobile = (username, data) => POST(`user/findPasswordByPhone/${username}/0`, data, true) // 忘记密码的手机
export const findPasswordByFund = (url, data) => POST(url, data, true)
export const putCollection = (code) => PUT(`lotteries/${code}/collections`, {}, true) //编辑的 添加收藏彩种
export const delCollection = (code) => DELETE(`lotteries/${code}/collections`, {}, true) // 编辑的 删除收藏彩种
export const getRate = (code) => GET(`account/digital/rate?coin=usdt`, {}, true) // 编辑的 删除收藏彩种
export const speedpayConfig = (code) => GET(`account/speedpay/config`, {}, true) //是否开启闪付接口
export const speedpayTrace = (data) => GET(`account/withdrawals/${data.id}/trace`, {}, false) //获取当前订单的状态
export const speedpayCommission = (data) => GET(`/account/withdrawals/${data.id}/speedpay_deposit_commission`, {}, true)
export const speedpayrechargeRecord = (data) => {
    if (data.status === -1) {
        return GET(`/account/deposits?page=${data.page}&start_time=${data.start_time}&end_time=${data.end_time}&currency_group=${data.currency_group}`, data, true) //充值订单列表
    } else {
        return GET(`/account/deposits?page=${data.page}&status=${data.status}&start_time=${data.start_time}&end_time=${data.end_time}&currency_group=${data.currency_group}`, data, true) //充值订单列表
    }
}
export const speedpaywithdrawRecord = (data) => {
    if (data.status === -1) {
        return GET(`/account/withdrawals?page=${data.page}&start_time=${data.start_time}&end_time=${data.end_time}&currency_group=${data.currency_group}`, {}, true) //获取提现列表
    } else {
        return GET(`/account/withdrawals?page=${data.page}&status=${data.status}&start_time=${data.start_time}&end_time=${data.end_time}&currency_group=${data.currency_group}`, {}, true) //获取提现列表
    }
}
export const usdtlabelConfig = (code) => GET(`account/deposits/usdt_label`, {}, true) //是否开启指定充提
export const thirdpartiesGames = (data) => GET(`thirdparties/games/list/${data.platform}/${data.type}/${data.client}/${data.page}`, {}, true) //获取三方电子游戏列表
export const thirdPlatforms = (code) => GET(`thirdparties/platforms`, {}, true) //
export const thirdGamesPopular = (data) => GET(`thirdparties/games/popular/${data.platform}/${data.game_type}/${data.client}/${data.page}`, {}, true) //获取热门游戏列表
export const thirdGamesCollect = (data) => GET(`thirdparties/games/collection/${data.platform}/${data.type}/${data.client}/${data.page}`, {}, true) //获取三方电子游戏收藏列表
export const thirdGameSearch = (data) => GET(`thirdparties/games/search/${data.platform}/${data.name}/${data.type}/${data.client}/page/${data.num}`, {}, true) //三方电子游戏搜索
export const gamesAdd = (data) => GET(`thirdparties/games/docollection/${data.platform}/${data.type}/${data.code}/add`, {}, true) //收藏
export const gamesDelete = (data) => GET(`thirdparties/games/docollection/${data.platform}/${data.type}/${data.code}/delete`, {}, true) //三方电子游戏移除收藏
export const gamesLogin = (data) => GET(`thirdparties/games/${data.platform}/${data.code}/inquiry`, {}, true) //三方游戏登入
export const promotionsGuests = (data) => GET(`/promotions/guests/`, {}, true) //活动列表
export const depositsProgress = (data) => GET(`/account/deposits/${data}/trace`, {}, true) //充值申请进度
export const thirdTransfers = (data) => GET(`/thirdparties/${data.code}/transfers?start_time=${data.start_time}&end_time=${data.end_time}`, {}, true) //三方游戏转账记录
export const withdrawalsMethod = (data) => GET(`/account/withdrawals/methods`, {}, true) //提现方式及额度
export const getthirdpartiesRecord = (data) => GET(`purchases/thirdparties/all/${data.type}/${data.start_date}/${data.end_date}${data.lt ? `?lt=${data.lt}` : ''}`, true)
export const speedpayOrderQuery = (data) => GET(`/account/speedpay/order/query`, {}, true) //闪付订单查询
export const unreadCountmsg = (data) => GET(`/messages/unreadCount`, {}, true) //站内信未读
export const readCountmsg = (data) => PATCH(`/messages/${data.id}`, {}, true) //站内信已读
export const cancelOrder = (data) => PATCH(`/account/deposits/${data.id}/cancel`, {reason: data.reason}, true) //站内信已读
export const getUbitRate = () => GET(`account/ubi/rate`, true) //获取Ubit汇率
export const ubitConfig = (code) => GET(`account/ubi/config`, {}, true) //优币提现是否开启
export const digitalUbitHttp = () => GET(`account/withdrawals/ubi/inquiry`, true) // Ubit提现初始化数据
export const delUbitHttp = (id, data) => DELETE(`account/ubi/${id}/`, data, true) //  删除Usdt
export const newDelUbitHttp = (id, data) => DELETE(`account/ubi/${id}/new`, data, true) //  新删除Usdt
export const addUbitHttp = (data) => POST(`account/ubi`, data, true) //  增加Ubit
export const getUbitHttp = (data) => GET(`account/ubi`, data, true) //  获取Ubit


export const getTopayRate = () => GET(`account/topay/rate`, true) //获取topay汇率
export const topayConfig = (code) => GET(`account/topay/config`, {}, true) //topay提现是否开启
export const digitalTopayHttp = () => GET(`account/withdrawals/topay/inquiry`, true) // Topay提现初始化数据
export const delTopayHttp = (id, data) => DELETE(`account/topay/${id}/`, data, true) //  删除topay
export const newDelTopayHttp = (id, data) => DELETE(`account/topay/${id}/new`, data, true) //  新删除topay
export const addTopayHttp = (data) => POST(`account/topay`, data, true) //  增加topay
export const getTopayHttp = (data) => GET(`account/topay`, data, true) //  获取topay


export const getEbpayRate = () => GET(`account/ebpay/rate`, true) //获取Ebpay汇率
export const ebpayConfig = (code) => GET(`account/ebpay/config`, {}, true) //Ebpay提现是否开启
export const digitalEbpayHttp = () => GET(`account/withdrawals/ebpay/inquiry`, true) // Ebpay提现初始化数据
export const delEbpayHttp = (id, data) => DELETE(`account/ebpay/${id}/`, data, true) //  删除Ebpay
export const newDelEbpayHttp = (id, data) => DELETE(`account/ebpay/${id}/new`, data, true) //  新删除Ebpay
export const addEbpayHttp = (data) => POST(`account/ebpay`, data, true) //  增加Ebpay
export const getEbpayHttp = (data) => GET(`account/ebpay`, data, true) //  获取Ebpay


export const getKdpayRate = () => GET(`account/kdpay/rate`, true) //获取kdpay汇率
export const kdpayyConfig = (code) => GET(`account/kdpay/config`, {}, true) //kdpay提现是否开启
export const digitalKdpayHttp = () => GET(`account/withdrawals/kdpay/inquiry`, true) // kdpay提现初始化数据
export const delKdpayHttp = (id, data) => DELETE(`account/kdpay/${id}/`, data, true) //  删除kdpay
export const newDelKdpayHttp = (id, data) => DELETE(`account/kdpay/${id}/new`, data, true) //  新删除kdpay
export const addKdpayHttp = (data) => POST(`account/kdpay`, data, true) //  增加kdpay
export const getKdpayHttp = (data) => GET(`account/kdpay`, data, true) //  获取kdpay


export const getJdpayRate = () => GET(`account/jdpay/rate`, true) //获取Jdpay汇率
export const jdpayConfig = (code) => GET(`account/jdpay/config`, {}, true) //Jdpay提现是否开启
export const digitalJdpayHttp = () => GET(`account/withdrawals/jdpay/inquiry`, true) // Jdpay提现初始化数据
export const delJdpayHttp = (id, data) => DELETE(`account/jdpay/${id}/`, data, true) //  删除Jdpay
export const newDelJdpayHttp = (id, data) => DELETE(`account/jdpay/${id}/new`, data, true) //  新删除Jdpay
export const addJdpayHttp = (data) => POST(`account/jdpay`, data, true) //  增加Jdpay
export const getJdpayHttp = (data) => GET(`account/jdpay`, data, true) //  获取Jdpay



export const getDepositsLlimits = (data) => GET(`account/deposits/limits`, data, true)

export const getLabelsUsdt = (data) => POST(`users/labels/usdt`, data, true)

export const getArticles = (data) => GET(`articles/${data}`, true)

export const getDepositsId = (data) => GET(`account/deposits/${data}`, true)

export const getWithdrawalsId = (data) => GET(`/account/withdrawals/${data}`, true)

export const getNoRegPlay = (data) => POST(`/user/getGuestAccount`, data, true)
