import Axios from 'axios'
import * as api from '../models'

export const getInquiry = async (data) => {
  const inquiry = await api.getPlayInquiry({ code: data.code, id: data.id })
  return {
    basicPrize: inquiry.prize,
    prize: inquiry.is_enable_extra ? inquiry.extra_prize : inquiry.prize,
    basicMultiple: inquiry.multiple,
    multiple: inquiry.is_enable_extra ? inquiry.extra : inquiry.multiple
  }
}

export const initBattingPage = async (data) => {
  return Axios.all([api.getNextOpen({ code: data.code, loading: data.loading ? true : data.loading }), api.getLatestOpen({ code: data.code, loading: data.loading ? true : data.loading })]).then(
    Axios.spread((getNextOpen, getLatestOpen) => {
      return {
        currentNumberTime: new Date(getNextOpen.end_time?.replace(/-/g, '/')) / 1000,
        currentTime: new Date(getNextOpen.now_time?.replace(/-/g, '/')) / 1000,
        currentNumber: getNextOpen.issue,
        lastNumber: getLatestOpen.issue,
        lotteryBalls: getLatestOpen.number,
        code: data.code
      }
    })
  )
}
