export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const SET_HASPREMISSION = 'setHaspermisson'
export const SET_WITHDRAWMENU = 'setwithdrawMenu'
export const SET_BALANCE = 'setBalance'
export const SET_GETCODECHANGE = 'setGetCodeChange'
export const SET_DJSRES = 'setDjsRes'
export const SET_REMBER = 'setRember'
export const SET_TABSINDEX = 'setTabsIndex'

