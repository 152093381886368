import { SET_PLAY, SET_USER_INPUT, SET_WAY_INQUIRY } from './actionTypes'

const reducer = (
  state = {
    userInput: '',
    play: {},
    basicPrize: 0
  },
  action
) => {
  switch (action.type) {
    case SET_USER_INPUT:
      return { ...state, userInput: action.payload }
    case SET_PLAY:
      return { ...state, play: action.payload }
    case SET_WAY_INQUIRY:
      return { ...state, basicPrize: action.payload.basicPrize }
    default:
      return state
  }
}

export default reducer
