import { timeFormatter } from '@/utils'
import { ADD_REQUEST, CLEAR_TIMER, DELETE_REQUEST, SET_IS_IN_BET, SET_LOTTERY_INQUIRY, SET_PRIZE, STE_CHANNEL, STE_LOTTERIES, STE_TIMER, TICK_TOCK } from './actionTypes'

export const setChannel = (payload) => ({ type: STE_CHANNEL, payload })

export const setLotteries = (payload) => (dispatch) => {
  const { currentNumberTime, currentTime } = payload.lottery

  dispatch({ type: CLEAR_TIMER, payload })
  dispatch({ type: STE_LOTTERIES, payload })

  let leftTime = Math.floor(currentNumberTime - currentTime)
  dispatch({ type: TICK_TOCK, payload: { code: payload.code, time: isNaN(currentNumberTime) || isNaN(currentTime) ? ['??', '??', '??'] : timeFormatter(leftTime) } })
  const timer = setInterval(() => {
    if (leftTime >= 1) {
      --leftTime
      dispatch({ type: TICK_TOCK, payload: { code: payload.code, time: timeFormatter(leftTime) } })
    } else {
      dispatch({ type: CLEAR_TIMER, payload })
    }
  }, 1000)

  dispatch({ type: STE_TIMER, payload: { code: payload.code, timer } })
}

export const addRequest = (payload) => ({ type: ADD_REQUEST, payload })
export const setIsInBet = (payload) => ({ type: SET_IS_IN_BET, payload })
export const deleteRequest = (payload) => ({ type: DELETE_REQUEST, payload })
export const setPrize = (payload) => ({ type: SET_PRIZE, payload })
export const setLotteryInquiry = (payload) => ({ type: SET_LOTTERY_INQUIRY, payload })
