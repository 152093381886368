import {
  LOGIN,
  LOGOUT,
  SET_HASPREMISSION,
  SET_BALANCE,
  SET_WITHDRAWMENU,
  SET_GETCODECHANGE,
  SET_DJSRES,
  SET_REMBER,
  SET_TABSINDEX,
} from './actionTypes'

const reducer = (
  state = {
    isLogin: false,
    hasPremission: false,// 登录时候需要的权限
    withdrawMenu: '1', //充值的导航默认值
    balance: '0',
    getCodeChange: false, //  充值页面定时器状态
    djsRes: '', // 充值页面显示的按钮上显示的倒计时
    isRemeber: true, //  登陆页面是否记住密码
    tabsIndex: 0,
  },
  action
) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, isLogin: true }
    case LOGOUT:
      return { ...state, isLogin: false }
    case SET_HASPREMISSION:
      return { ...state, hasPremission: action.payload.hasPremission }
    case SET_BALANCE:
      return { ...state, balance: action.payload }
    case SET_WITHDRAWMENU:
      return { ...state, withdrawMenu: action.payload.withdrawMenu }
    case SET_GETCODECHANGE:
      return { ...state, getCodeChange: action.payload.getCodeChange }
    case SET_DJSRES:
      return { ...state, djsRes: action.payload.djsRes }
    case SET_REMBER:
      return { ...state, isRemeber: action.payload.isRemeber }
    case SET_TABSINDEX:
      return { ...state, tabsIndex: action.payload.tabsIndex }
    default:
      return state
  }
}

export default reducer
