import * as api from '../models'

export const stopTracking = async (data) => {
  const trackes = await api.getTrackesById({ id: data.id })
  return await api.stopTrack({
    id: data.id,
    params: {
      issues: trackes.tickets
        .map((element) => (element.status === 0 ? element.issue : []))
        .filter((element) => element.length !== 0)
        .join(',')
    }
  })
}
