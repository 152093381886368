import styles from '@@T/loading.module.scss'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'

const Loading = () => {
  const requestQueue = useSelector((state) => state.system.requestQueue)
  return ReactDOM.createPortal(
    <>
      {Object.keys(requestQueue).length && (
        <div className={styles.loading}>
          <div className={styles.img}></div>
        </div>
      )}
    </>,
    document.querySelectorAll('body')[0]
  )
}

export default Loading
