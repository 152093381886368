import { getItem } from '@/utils/storage'
import { Dialog } from 'antd-mobile'
import React from 'react'
import { useLocation, withRouter } from 'react-router-dom'
import styles from '@@T/tabber/tabber.module.css'
import images from './requireImg'

const tarbarArr = [
  {
    img: images.home,
    activeImg: images.home_active,
    text: '首页',
    path: process.env.AliTYPE === 'wap' ? '/' : '/home'
  },
  {
    img: images.draw,
    activeImg: images.draw_active,
    text: '开奖',
    path: '/draw'
  },
  {
    img: images.betslip,
    activeImg: images.betslip_active,
    text: '注单',
    path: '/betslip'
  },
  {
    img: images.serivce,
    activeImg: images.serivce_active,
    text: '客服',
    path: '/serivce'
  },
  {
    img: images.my,
    activeImg: images.my_active,
    text: '我的',
    path: '/my'
  }
]

function Tabber({ history }) {
  const location = useLocation()
  const pathName = location.pathname
  const hasLogin = getItem('userInfo')?.token ? getItem('userInfo')?.token : ''
  const toTipsLogin = () => {
    Dialog.show({
      content: (
        <>
          <div className="mui-popup-title">提示</div>
          <div className="mui-popup-text">此功能仅对登录用户开放</div>
        </>
      ),
      closeOnAction: true,
      bodyClassName: 'addhas',
      actions: [
        [
          {
            key: 'cancel',
            text: '取消',
            style: {
              fontFamily: 'PingFangSC-Medium, PingFang SC',
              fontWeight: 400,
              color: '#E7AB01',
              fontSize: '17px'
            }
          },
          {
            key: 'confirm',
            text: '去登录',
            bold: true,
            style: {
              fontFamily: 'PingFangSC-Medium, PingFang SC',
              fontWeight: 400,
              color: '#E7AB01',
              fontSize: '17px'
            },
            onClick: () => {
              const urlParams = new URLSearchParams(location.search)
              const agentId = urlParams.get('agentId')
              history.push({ pathname: `/login`, search: agentId ? `?agentId=${agentId}` : '', query: { id: 1 } })
            }
          }
        ]
      ]
    })
  }
  const toChange = (path) => {
    if (hasLogin) {
      history.push(path)
    } else {
      toTipsLogin()
    }
  }
  return tarbarArr.some((element) => element.path === pathName) ? (
    <ul className={styles['tabbar-box']}>
      {tarbarArr.map((item, index) => (
        <li key={index} onClick={() => toChange(item.path)}>
          <img className={styles.icons} alt="" src={pathName === item.path ? item.activeImg : item.img} />
          <span className={pathName === item.path ? styles['tabbar-active'] : ''}>{item.text}</span>
        </li>
      ))}
    </ul>
  ) : (
    ''
  )
}

export default withRouter(Tabber)
