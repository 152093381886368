// import React from 'react'
import store from '@/store'
import { chineseHint } from '@/utils/chineseHint'
import { getItem } from '@/utils/storage'
import { Toast } from 'antd-mobile'
import Axios from 'axios'
import { HashRouter } from 'react-router-dom'
import React from "react";
const router = new HashRouter()
let buildUrl = process.env.AliTYPE === 'wap' ? window.location.protocol + '//' + window.location.hostname + '/v3/' : window.baseUrl || process.env.NEED_STAND.api
// let buildUrl =  process.env.NEED_STAND.api
const instance = Axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.NEED_STAND.api : buildUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})
instance.interceptors.request.use(
  (config) => {
    if (getItem('token') && getItem('token') !== 'null') {
      config.headers['authorization'] = `Bearar ${getItem('token')}` //携带权限参数
    }
    if (config.loading) {
      store.dispatch({ type: 'addRequest', payload: { id: config.url.replace(/\//g, '_') } })
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  function (response) {
    setTimeout(()=>{
      store.dispatch({ type: 'deleteRequest', payload: { id: response.request.responseURL.split('/v3/')[1].replace(/\//g, '_') } })
    },800)
    return response.data
  },
  function (error) {
    // console.log(JSON.stringify(error.response))
    store.dispatch({ type: 'deleteRequest', payload: { id: error.response.url } })
    if (error?.response?.status === 401) {
      // 当返回状态为401时去到登陆页
      router.history.push('/login')
    }
    // 当错误的返回为其他文本的时候，展示文本，并且中止加载动画
    if (error?.response?.data?.errors) {
      // console.log(error.response.data.errors)
      for (const key in error.response.data.errors) {
        let message = error.response.data.errors[key]

        for (const keys in chineseHint) {
          // console.log(message)
          // console.log(keys)

          if (message.indexOf(keys) > -1) {
            message = chineseHint[keys]
          }
        }
        Toast.show({
          duration: 3000,
          content: message
        })
      }
    }
    // console.log(JSON.stringify(error.response.data.errors.account))
    return Promise.reject(error)
  }
)
React.Component.prototype.$axios = instance
const GET = (url, loading) => instance.get(url, url.includes(`trace`) ? {} : { loading })
const POST = (url, params, loading) => instance.post(url, params, { loading })
const PATCH = (url, params, loading) => instance.patch(url, params, { loading })
const DELETE = (url, params, loading) => instance.delete(url, { data: params, loading })
const PUT = (url, params, loading) => instance.put(url, { data: params, loading })
export { GET, POST, PATCH, DELETE, PUT }
