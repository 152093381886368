import React, { Suspense } from 'react'
import { renderRoutes } from 'react-router-config'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import '@@T/App.css'
// const routes = require('./routes').routes
import routes from '@@R/routes'
const DEFAULT_SCENE_CONFIG = {
  enter: 'from-right',
  exit: 'to-right'
}

const getSceneConfig = (location) => {
  const matchedRoute = routes.find((config) => new RegExp(`^${config.path}$`).test(location.pathname))
  return (matchedRoute && matchedRoute.sceneConfig) || DEFAULT_SCENE_CONFIG
}

const PageContent = () => () =>
  (
    <div className="page-content">
      <Suspense fallback={<div className="page-loading"> </div>}>{renderRoutes(routes)}</Suspense>
    </div>
  )

let oldLocation = null
const AuthRoute = () => {
  const history = useHistory()
  const location = useLocation()

  let classNames = ''
  if (history.action === 'PUSH') {
    classNames = 'forward-' + getSceneConfig(location).enter
  } else if (history.action === 'POP' && oldLocation) {
    classNames = 'back-' + getSceneConfig(oldLocation).exit
  }

  // 更新旧location
  oldLocation = location

  return (
    <TransitionGroup className={'router-wrapper'} childFactory={(child) => React.cloneElement(child, { classNames })}>
      <CSSTransition timeout={350} key={location.pathname}>
        <Switch location={location}>
          {routes.map((config, index) => (
            <Route exact key={index} path={config.path} component={PageContent()} />
          ))}
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default AuthRoute
