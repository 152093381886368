import { fix_jingdu } from '@/utils'
import * as api from '../models'

export const getRebete = async (data) => {
  const { user_prize_group, max_prize_group, min_prize_group, prize_group, issues, max_traces } = await api.getLotteryInquiry({ code: data.code })

  let max = 0
  let min = 0
  // debugger
  if (user_prize_group <= max_prize_group && user_prize_group >= min_prize_group) {
    max = user_prize_group
    min = min_prize_group
  } else if (user_prize_group >= max_prize_group) {
    max = max_prize_group
    min = min_prize_group
  } else {
    max = min_prize_group
    min = user_prize_group
  }

  return {
    prize_group,
    max_traces,
    issues,
    fandian: [
      {
        label: max + '-' + fix_jingdu(((user_prize_group - max) / prize_group) * 100, 2) + '%',
        value: max
      },
      {
        label: min + '-' + fix_jingdu(((user_prize_group - min) / prize_group) * 100, 2) + '%',
        value: min
      }
    ],
    user_prize_group
  }
}
