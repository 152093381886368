import { ADD_REQUEST, CLEAR_TIMER, DELETE_REQUEST, SET_IS_IN_BET, SET_LOTTERY_INQUIRY, SET_PRIZE, STE_CHANNEL, STE_LOTTERIES, STE_TIMER, TICK_TOCK } from './actionTypes'

const initialState = {
  channel: null,
  lotteries: {},
  requestQueue: {},
  prize: '',
  isInBet: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STE_LOTTERIES:
      return { ...state, lotteries: { ...state.lotteries, [action.payload.code]: { ...state.lotteries[action.payload.code], data: action.payload.lottery } } }
    case TICK_TOCK:
      return { ...state, lotteries: { ...state.lotteries, [action.payload.code]: { ...state.lotteries[action.payload.code], countdown: action.payload.time } } }
    case STE_TIMER:
      return { ...state, lotteries: { ...state.lotteries, [action.payload.code]: { ...state.lotteries[action.payload.code], timer: action.payload.timer } } }
    case CLEAR_TIMER:
      if (state.lotteries[action.payload.code]?.timer) {
        clearInterval(state.lotteries[action.payload.code].timer)
      }
      return state
    case STE_CHANNEL:
      return { ...state, channel: action.payload.channel }
    case ADD_REQUEST:
      return { ...state, requestQueue: { ...state.requestQueue, [action.payload.id]: action.payload.func } }
    case DELETE_REQUEST:
      return { ...state, requestQueue: delete state.requestQueue[action.payload.id] }
    case SET_PRIZE:
      return { ...state, prize: action.payload.prize }
    case SET_LOTTERY_INQUIRY:
      return { ...state, lotteries: { ...state.lotteries, [action.payload.code]: { ...state.lotteries[action.payload.code], issues: action.payload.issues, maxTrack: action.payload.maxTrack } } }
    case SET_IS_IN_BET:
      return { ...state, isInBet: action.payload.isInBet }
    default:
      return state
  }
}

export default reducer
