
import home from "@@A/images/home/bottom/home.png"
import home_active from "@@A/images/home/bottom/home_active.png"
import draw from "@@A/images/home/bottom/draw.png"
import draw_active from "@@A/images/home/bottom/draw_active.png"
import betslip from "@@A/images/home/bottom/betslip.png"
import betslip_active from "@@A/images/home/bottom/betslip_active.png"
import serivce from "@@A/images/home/bottom/serivce.png"
import serivce_active from "@@A/images/home/bottom/serivce_active.png"
import my from "@@A/images/home/bottom/my.png"
import my_active from "@@A/images/home/bottom/my_active.png"

const images = {
    home,
    home_active,
    draw,
    draw_active,
    betslip,
    betslip_active,
    serivce,
    serivce_active,
    my,
    my_active,
}
export default images
