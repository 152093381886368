import store from '@/store'
import AuthRoute from '@@/router'
import 'lib-flexible'
import Pusher from 'pusher-js'
import {Provider} from 'react-redux'
import {HashRouter as Router} from 'react-router-dom'
import '@@T/App.css'
import Loading from './components/Loading'
import Tabber from '@@C/tabber'
import React, {useEffect} from "react";
import {httpService} from '@/utils'

import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react'
import Fp from "./components/fp";

class App extends React.Component {
    componentDidMount() {
        console.log(1)
        httpService()
        const pusher = new Pusher('7fdaeba0a6603f97adc0', {cluster: 'ap3'})
        store.dispatch({type: 'setChannel', payload: {channel: pusher.subscribe('betData')}})


        // 页面初始化时清除一次含有特定键的缓存项
        this.clearCacheWithKey('rule_list');

        // 每隔 30 分钟清除一次缓存
        this.interval = setInterval(() => {
            this.clearCacheWithKey('rule_list');
        }, 30 * 60 * 1000);

    }

    componentWillUnmount() {
        clearInterval(this.interval); // 在组件销毁时清除定时器
    }


    // 清除含有特定键的缓存项
    clearCacheWithKey(key) {
        for (let i = 0; i < localStorage.length; i++) {
            const itemKey = localStorage.key(i);
            if (itemKey.includes(key)) {
                localStorage.removeItem(itemKey);
            }
        }
    }


    render() {
        return (
            <>
                <Provider store={store}>
                    <Router>
                        <Tabber/>
                        <AuthRoute/>
                    </Router>
                    <Fp></Fp>
                    <Loading/>
                </Provider>
            </>
        )
    }
}

export default App
