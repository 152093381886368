export const STE_LOTTERIES = 'setLotteries'
export const STE_CHANNEL = 'setChannel'
export const TICK_TOCK = 'tick-tock'
export const STE_TIMER = 'setTimer'
export const CLEAR_TIMER = 'clearTimer'
export const ADD_REQUEST = 'addRequest'
export const DELETE_REQUEST = 'deleteRequest'
export const SET_PRIZE = 'setPrize'
export const SET_LOTTERY_INQUIRY = 'setLotteryInquiry'
export const SET_IS_IN_BET = 'setIsInBet'
